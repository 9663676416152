import React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import styles from "./blog-post.module.scss"
import Img from "gatsby-image"
import License from "../components/license"
import SEO from '../components/seo'
import _ from "lodash"
import { MDXRenderer } from "gatsby-plugin-mdx"
import {
  FiArrowLeftCircle,
  FiArrowRightCircle,
  FiWatch,
  FiCalendar,
  FiPenTool,
  FiTag
} from 'react-icons/fi'

import "katex/dist/katex.min.css"

const BannerImage = props => {
  const link = props.img.url ? <a href={props.img.url}>Lenke til original</a> : null
  const cred = props.img.cred ? "Av: " + props.img.cred + ". " : null
  const license = <License license={ props.img.license } />
  const style = props.img.narrow === true ? styles.bannerImageNarrow : styles.bannerImageFullWidth
  return(
    <figure className={style}>
      <Img
        fluid={props.img.featured_image.childImageSharp.fluid}
        alt={props.img.alt}
      />
      <figcaption>
        <span className={styles.imageTitle} dangerouslySetInnerHTML={ { __html: props.img.title } } />
        <span className={styles.imageCreds}>
          <span dangerouslySetInnerHTML={ { __html: cred } } />
          <span className={styles.imagelicense}>{ license } </span>
          <span className={styles.imageLink}>{ link } </span>
        </span>
      </figcaption>
    </figure>
  )
}

export default ({ pageContext, data }) => {
  const post = data.mdx
  const prev = pageContext.prev
  const next = pageContext.next

  let featured = post.frontmatter.featured
  let img = featured ? <BannerImage img={ featured } /> : null
  let SEOImgSrc = featured ? featured.featured_image.childImageSharp.resize.src : null

  return(
    <Layout>
      <SEO title={ post.frontmatter.title } description={ post.excerpt } image={ SEOImgSrc } />
      { img }
      <article id={ post.fields.slug } className={styles.blogPost}>
        <span className={styles.category}>
          { post.frontmatter.categories.join(", ") }
        </span>
        <h1>{ post.frontmatter.title }</h1>
        <div className={styles.metadata}>
          <div className={styles.author}><FiPenTool /> {post.frontmatter.author.display_name}</div>
            <div className={styles.time}><FiCalendar /> { post.frontmatter.date }</div>
            <div className={styles.length}><FiWatch /> { post.wordCount.words } ord</div>
        </div>
        <div className={ styles.bodytext }>
          <MDXRenderer>{ post.body }</MDXRenderer>
        </div>
        <div className={styles.tags}>
          { post.frontmatter.tags.map((tag, i) => (
            <AniLink key={`tag-link-${i}`} fade className={styles.tag} to={ `/tags/${_.kebabCase(tag)}` }>
              <FiTag /> { tag }
            </AniLink>
          ))}
        </div>
      </article>

      <nav className={styles.prevnext}>
        { next && (
          <AniLink swipe direction="left" to={next.fields.slug}>
            <FiArrowLeftCircle /> {next.frontmatter.title}
          </AniLink>
        )}
        { prev && (
          <AniLink swipe direction="right" to={prev.fields.slug}>
            {prev.frontmatter.title} <FiArrowRightCircle />
          </AniLink>
        )}
      </nav>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        title
        categories
        tags
        author {
          display_name
        }
        date(
          formatString: "LLLL"
          locale: "nb-NO"
        )
        featured {
          url
          cred
          license
          alt
          title
          narrow
          featured_image {
            childImageSharp {
              fluid(maxWidth:1920) {
                ...GatsbyImageSharpFluid
              }
              resize(width:900) {
                src
              }
            }
          }
        }
      }
      fields {
        slug
      }
      wordCount {
        words
      }
    }
  }
`
