import React from 'react'

import {
  FaCreativeCommonsBy,
  FaCreativeCommonsNc,
  FaCreativeCommonsNd,
  FaCreativeCommonsSa,
  FaCreativeCommons,
  FaCreativeCommonsPd
} from 'react-icons/fa'

const licenses = {
  'PD': {
    icons: [ <FaCreativeCommonsPd key="pd"/> ],
    longname: 'I offentlig eie',
    url: 'https://en.wikipedia.org/wiki/Public_domain'
  },
  'BY': {
    url: 'https://creativecommons.org/licenses/by',
    icons: [ <FaCreativeCommons key="cc"/>, <FaCreativeCommonsBy key="by"/> ],
    longname: 'Creative Commons Attribution'
  },
  'BY-SA': {
    url: 'https://creativecommons.org/licenses/by-sa',
    icons: [ <FaCreativeCommons key="cc"/>, <FaCreativeCommonsBy key="by"/>, <FaCreativeCommonsSa key="sa"/> ],
    longname: 'Creative Commons Attribution-ShareAlike'
  },
  'BY-ND': {
    url: 'https://creativecommons.org/licenses/by-nd',
    icons: [ <FaCreativeCommons key="cc"/>, <FaCreativeCommonsBy key="by"/>, <FaCreativeCommonsNd key="nd"/> ],
    longname: 'Creative Commons Attribution-NoDerivs'
  },
  'BY-NC': {
    url: 'https://creativecommons.org/licenses/by-nc',
    icons: [ <FaCreativeCommons key="cc"/>, <FaCreativeCommonsBy key="by"/>, <FaCreativeCommonsNc key="nc"/> ],
    longname: 'Creative Commons Attribution-NonCommercial'
  },
  'BY-NC-SA': {
    url: 'https://creativecommons.org/licenses/by-nc-sa',
    icons: [ <FaCreativeCommons key="cc"/>, <FaCreativeCommonsBy key="by"/>, <FaCreativeCommonsNc key="nc"/>, <FaCreativeCommonsSa key="sa"/> ],
    longname: 'Creative Commons Attribution-NonCommercial-ShareAlike'
  },
  'BY-NC-ND': {
    url: 'https://creativecommons.org/licenses/by-nc-nd',
    icons: [ <FaCreativeCommons key="cc" />, <FaCreativeCommonsBy key="by" />, <FaCreativeCommonsNc key="nc" />, <FaCreativeCommonsNd key="nd" /> ],
    longname: 'Creative Commons Attribution-NonCommercial-NoDerivs'
  }
}

let parseCC = (str) => {
  let abbreviated = str.replace(/Creative ?Commons/, 'CC')
    .replace(/Attribution/, 'BY')
    .replace(/NonCommercial/, 'NC')
    .replace(/NoDerivs/, 'ND')
    .replace(/ShareAlike/, 'SA')

  const cc_regex = /^CC ((?:(?:BY|ND|NC|SA|PD)-?)*)( \d.\d)?$/
  let match = abbreviated.match(cc_regex)

  if (match === null) {
    return null
  } else {
    let lic = licenses[match[1]]
    let ver = match[2]
    let url = lic.url
    let fullname = lic.longname
    if (ver) {
      url = url + "/" + ver
      fullname = fullname + " " + ver
    }

    return(
      <a className="license" href={ url } title={ fullname }>
        { lic.icons }
      </a>
    )
  }
}

const License = (props) => {
  if (props.license === null || props.license === '') {
    return null
  } else {
    const cc = parseCC(props.license)
    if (cc) {
      return(cc)
    } else {
      return(<span>Lisens: {props.license}</span>)
    }
  }
}

export default License
